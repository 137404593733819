<template>
<Card>
    <template #content>
		<div v-if="!errores">
        	<p>Este es el listado preliminar de pagos a ser procesados. Si desea evitar que el pago se aplicado automáticamente a los documentos con saldo pendiente, asegúrese de desmarcar el recuadro "Aplicar".</p>
		</div>
		<div v-else>
			<p>No es posible continuar debido a que se han encontrado inconsistencias en el archivo de importación. Por favor revise los errores encontrado e intente de nuevo.</p>
		</div>
        <Divider />      		    
        <DataTable :value="pagos" editMode="cell" dataKey="rec.id_movimiento" v-model:selection="selectedPagos" :rowClass="rowClass" v-model:expandedRows="expandedRows" responsiveLayout="scroll" class="p-datatable-sm"
		filterDisplay="menu">
			<template #header>        
				<div class="p-d-flex p-jc-end">
					<Button label="Procesar Pagos" @click="procesar()" class="p-button-warning" icon="pi pi-cog" v-show="!errores" :disabled="totalTrxAEmitir==0"/>
				</div>
			</template>		
			<template #empty>
				No hay pagos a procesar 
			</template>		
			<Column field="err_msg" header="Error" :style="errores?'':'display: none'">
                <template #body="slotProps">
                   	<div style="font-weight: 700;color: #FF5252;" class="p-d-flex p-ai-center">
						<icon class="pi pi-ban p-mr-2" style="font-size: 2rem;color: #FF5252;"/>
                        <div>{{slotProps.data.err_msg}}</div>
                    </div>
                </template>
			</Column>	
			<Column field="aplicar" header="Aplicar" :style="errores?'display: none' : ''">	
                <template #body="slotProps">
					<Checkbox v-model="slotProps.data.aplicar" :binary="true"/>
                </template>				
			</Column>
			<Column field="tipo_movimiento" header="Tipo" />
			<Column field="serie" header="Serie" /> 
			<Column field="rec.no_docto" header="#" >
				<template #body="slotProps">
					{{slotProps.data.rec.no_docto}}
				</template>
			</Column>   
            <Column field="cta_habiente" :header="tituloCtaHabiente"/>
            <!-- <Column field="vigencia_final" header="Vencimiento"/>                    -->
            <!-- <Column field="plantilla" header="Plan"/> -->
			<Column field="rec.referencia" header="Referencia"/>
            <Column field="rec.total" header="Total"> 
				<template #body="slotProps" sortable>
                   	<div class="p-d-flex p-jc-end">
                        <div>{{slotProps.data.rec.id_moneda}} {{formatCurrency(slotProps.data.rec.total)}}</div>
                    </div>                									
				</template>
			</Column>	
            <!-- <template #expansion="slotProps">
                <div class="orders-subtable">
                    <DataTable :value="slotProps.data.detalle" dataKey="id_mov_det" responsiveLayout="scroll" class="p-datatable-sm"  selectionMode="single">
                        <Column field="codigo" header="Código" style="flex: 0 0 8rem"></Column>
                        <Column field="producto" header="Producto" style="flex: 0 0 2rem"></Column>
                        <Column field="cantidad" header="Cantidad" style="flex: 0 0 2rem"></Column>
						<Column field="precio_unitario" header="Precio U." style="flex: 0 0 4rem">
                          <template #body="slotProps">
                                {{formatCurrency(slotProps.data.precio_unitario)}}
                            </template>
                        </Column>
						<Column field="total" header="Total">					
                            <template #body="slotProps">
                                {{formatCurrency(slotProps.data.total)}}
                            </template>
                        </Column>
                    </DataTable>
                </div>
            </template> -->
			<ColumnGroup type="footer">
				<Row>
					<Column footer="Documentos:" :colspan="4" footerStyle="text-align:right" />
					<Column :footer="totalTrxAEmitir" />
					<Column :footer="montoTotalAOperar" :colspan="3" footerStyle="text-align:right" />
					<!-- <Column :footer="montoTotalAOperar" />					 -->
				</Row>
			</ColumnGroup>					
        </DataTable>				
    </template>
    <template #footer>        
        <div class="p-d-flex p-jc-between">
            <Button label="Regresar"  @click="prevPage()" icon="pi pi-angle-left"/>
            <Button label="Procesar Pagos" @click="procesar()" class="p-button-warning" icon="pi pi-cog" v-show="!errores" :disabled="totalTrxAEmitir==0"/>
        </div>
    </template>
</Card>

<Dialog v-model:visible="procesandoDlg" :showHeader="true" :modal="true" :closable="false">			
	<div class="flex align-content-center flex-wrap card-container" :style="{width: '500px', height: '250'}">
		<div class="p-d-flex p-ai-center p-jc-center p-mb-2">
			<img src="/assets/layout/images/document.gif" width="64" height="64"/>
		</div>		
		<div class="p-d-flex p-ai-center p-jc-center p-text-bold">			
            <div class="text-lg">Por favor espere un minuto mientras se procesan los pagos...</div>
		</div>
	</div>
</Dialog>

</template>
<script>
import CxcService from '../../service/cxc.service';
import { CURRENT_APP_MODE, MDS_APP_MODE } from '../../service/constants';
import Divider from 'primevue/divider';

export default {
    name: 'CxcImpRevision',
    components: {
        Divider
    },    
	props: {
		formData: {
			type: Object,
			Default() {
				return {
					id_perfil: '',
					id_moneda: '',
					archivo: '',
					errores: false,
					movs: [],					
				}
			}
		}
	},          
    data() {        
        return {         
            appMode: CURRENT_APP_MODE,
			procesandoDlg: false,
			tituloCtaHabiente: (CURRENT_APP_MODE == MDS_APP_MODE? 'Inmueble': 'Cta.Habiente'),
			id_moneda: this.formData.id_moneda,
			errores: this.formData.errores,
			pagos: this.formData.movs,
			selectedPagos: [],
			gran_total: 0.0,
			expandedRows: [],
        }
    },
    async mounted()
    {
		this.gran_total = 0;
		for(var i = 0; i < this.pagos.length; i++)
		{
			this.gran_total = this.gran_total + this.pagos[i].rec.total;			
		}
    },
	computed:{
		totalTrxAEmitir()
		{
			if(this.pagos) {
				return this.pagos.length;
			} else {
				return 0;
			}
		},
		montoTotalAOperar()
		{
			return `Total: ${this.id_moneda} ${this.formatCurrency(this.gran_total)}`;
		}			
	},    
    methods: {
		async procesar() {
			this.procesandoDlg = true;
			try {
				
				let movs = await CxcService.procesarPagosBatch(this.$store.state.auth.currentAppCtx, this.pagos);

				let formData = {
					id_perfil: this.id_perfil,
					id_moneda: this.id_moneda,					
					archivo: this.archivo,
					errores: this.errores,
					movs: movs
				};
				this.procesandoDlg = false;

				this.nextPage();
			} catch(err) {
				this.procesandoDlg = false;
				this.$toast.add({severity:'error', summary: 'Error', detail: err, life: 3000})
			}
		},
        async nextPage() {
			// var emisiones = await this.cargos.filter(cargo => cargo.emitido);

			let formData = {
				id_perfil: this.id_perfil,
				id_moneda: this.id_moneda,
				archivo: this.archivo,
				errores: this.errores,
				movs: this.pagos,
			};

            this.$emit('nextPage', {condiciones: formData, pageIndex: 1});
        },
        onRowSelect(data) {
            data.aplicar = !data.aplicar;				
			return data.aplicar;			
        },
		rowClass(data)
		{
			var className = (data.emitir?"emitir-row":"regular-row");
			return className;
		},
		findIndexMovimiento(idMovimiento)
		{
			var index = -1;
			for(var i=0; i<this.pagos.length; i++)
			if(this.pagos[i].id_movimiento === idMovimiento) {
				index = i;
				break;
			}

			return index;
		},
		formatCurrency(value) {
			return parseFloat(value).toLocaleString('en-US', {minimumFractionDigits: 2});
		},	        
        prevPage() {
            this.$emit('prevPage', {pageIndex: 1});
        }
    }
}
</script>

<style scoped lang="scss">
::v-deep(b) {
    display: block;
}

::v-deep(.p-card-body) {
    padding: 2rem;
}

::v-deep(.regular-row) {
	font-weight: 300;
	background: white;
}

::v-deep(.emitir-row) {
	font-weight: bold;
	background:white;
}

</style>